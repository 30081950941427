window.addEventListener("load", function() {
  $("#instagramCarousel").slick({
    lazyLoad: "ondemand",
    rows: 0,
    dots: true,
    slidesToShow: 4,
    slidesToScroll: 4,
    autoplay: true,
    pauseOnHover: true,
    arrows: true,
    prevArrow: $("#instagramPrev"),
    nextArrow: $("#instagramNext"),
    appendDots: $("#instagramDots"),
    responsive: [
      {
        breakpoint: 1199.98,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          arrows: true,
          dots: true
        }
      },
      {
        breakpoint: 991.98,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          arrows: true,
          dots: true
        }
      },
      {
        breakpoint: 767.98,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  });
});
